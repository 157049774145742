/*Google Font*/
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


* {
    font-family: "Quicksand";
    /*color: #333;*/
    margin: 0px;
}
.navbar {
    padding: 10px;
    display: flex;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    border-bottom: 1px solid #f2f2f2;
}
.navbar a {
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
}

select, option, label, button {
    color: black;
    padding: 3px;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    border-color: black;
    margin-bottom: 4px;
}

/*button{*/
/*    color: #f1356d;*/
/*    padding: 3px;*/
/*    font-size: 16px;*/
/*    border-color: #f1356d;*/
/*    margin: 2px;*/
/*}*/

@media screen and (max-width: 800px){
    .navbar {
        padding: 6px;
        max-width: 600px;
        font-size: 12px;
    }
    .navbar a {
        margin-left: 12px;
        padding: 4px;
    }
}

@media screen and (max-width: 600px){
    .navbar {
        padding: 4px;
        display: block;
        max-width: 500px;
        font-size: 10px;
    }
    .navbar a {
        margin-left: 8px;
        padding: 2px;
    }
}

@media screen and (max-width: 400px){
    .navbar {
        padding: 2px;
        max-width: 390px;
        font-size: 8px;
    }

    .navbar a {
        margin-left: 4px;
        padding: 2px;
    }
}

.navbar h1 {
    color: black;
}
.navbar .links {
    margin-left: auto;
}

.navbar a:hover {
    color: black;
}
.content {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
    display: block;

}

.notfound a{
    margin-top: 10px;
    text-decoration: none;
}

/*Three Fiber Scene */

.ThreeScene{
    position: relative;
    text-align: center;
    color: white;
}
.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}
.bottom-right{
    position: absolute;
    bottom: 8px;
    right: 26px;
}


.ThreeSceneTreePage{
    display: flex;
    flex-flow: row ;
}
.TreeBase{
    width: 400px;
    /*height:  600px;*/
    overflow: scroll;
    text-align: left;
    background-color: gainsboro;
    color: black;
}

.TreeView {

}

.TreeNode{
    list-style-type: none;
    color: black;
    font-size: 12px;
}

/*-----------------Contextmenü-------------------------------------*/
#context-menu-tree {
      background-color: #fffbf0;
      border: thin solid #000000;
      border-radius: 0 .1em .1em;
      position: absolute;
      z-index: 999;
      padding: 2px;
}
#context-menu-tree li {
    list-style-type: none;
    line-snap: none;
}
#context-menu-tree button {
    cursor: pointer;
    background: transparent;
    border: 0;
    font-size: 12px;
    padding: 0px;
    text-align: left;
}

#context-menu-threefiber {
    background-color: #fffbf0;
    border: thin solid #000000;
    border-radius: 0 .1em .1em;
    position: absolute;
    z-index: 999;
    padding: 2px;
}
#context-menu-threefiber li {
    list-style-type: none;
    line-snap: none;
}
#context-menu-threefiber button {
    cursor: pointer;
    background: transparent;
    border: 0;
    font-size: 12px;
    padding: 0px;
    text-align: left;
}
[hidden] {
    display: none;
}